<script lang="ts">
    import { onMount, tick } from 'svelte';
    import { writable } from 'svelte/store';
    import SuggestionCard from './SuggestionCard.svelte';
    import ExploreTab from './exploreTab.svelte';
    import Button from '$lib/components/ui/button/button.svelte';
    import ArrowTopRight from '@/Icons/ArrowTopRight.svelte';
    import { decryptBase64 } from '../utility/crypt/decrypt.js';
    import { renderAvifToCanvas } from '../utility/render/avifToCanvas.js';
    import * as Dialog from '$lib/components/ui/dialog/index.js';

    interface Post {
        attachment_id: string;
        'aspect-ratio': number;
        media_src?: string;
        'media-src'?: string;
        attachment_key: string;
        post_url: string;
        author_avatar: string;
        author_name: string;
        author_username: string;
    }

    export let authUser;
    console.log(authUser);
    export let posts: Post[];
    console.log(posts);
    export let isMobile: boolean;
    export let suggestions;
    export let hide_suggestions_slider: boolean;
    export let feed_suggestions_card_per_page: number;

    const currentPage = writable(2);
    let gridElement: HTMLElement;
    const loadingMore = writable(false);
    const selectedPost = writable<Post | null>(null);
    const modalOpen = writable(false);
    const loadingImage = writable(false);

    let dialogCanvas: HTMLCanvasElement;

    $: ({ squarePosts, highPosts } = separatePosts(posts));
    $: chunkedProfiles = chunkArray(suggestions, isMobile ? 2 : feed_suggestions_card_per_page);

    function separatePosts(posts: Post[]): { squarePosts: Post[]; highPosts: Post[] } {
        return posts.reduce(
            (acc, post) => {
                const aspectRatio = post['aspect-ratio'];
                if (aspectRatio > 0.46 && aspectRatio < 0.76) {
                    acc.highPosts.push(post);
                } else {
                    acc.squarePosts.push(post);
                }
                return acc;
            },
            { squarePosts: [] as Post[], highPosts: [] as Post[] }
        );
    }

    async function loadAndDisplay(post: Post, canvas: HTMLCanvasElement, wrapper: HTMLElement): Promise<void> {
        try {
            const mediaUrl = post.media_src || post['media-src'];
            if (!mediaUrl) throw new Error(`Media source URL is undefined for post ${post.attachment_id}`);

            const response = await fetch(mediaUrl);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            const encryptedData = await response.json();
            if (!encryptedData.ct || !encryptedData.iv) throw new Error('Encrypted data is missing required fields');

            const decryptedArrayBuffer = await decryptBase64(encryptedData.ct, encryptedData.iv, post.attachment_key);
            await renderAvifToCanvas(decryptedArrayBuffer, canvas);

            canvas.style.cursor = 'pointer';
            wrapper.classList.remove('bg-skeleton-anim-wrapper');
            canvas.style.opacity = '1';
        } catch (error) {
            console.error(`Error rendering canvas for post ${post.attachment_id}:`, error);
            displayErrorOnCanvas(canvas);
            wrapper.classList.remove('bg-skeleton-anim-wrapper');
            wrapper.classList.add('image-load-error');
        }
    }

    async function loadImageForDialog(post: Post): Promise<void> {
        await tick();
        if (!dialogCanvas) {
            console.error('dialogCanvas is not available');
            return;
        }

        try {
            const mediaUrl = post.media_src || post['media-src'];
            if (!mediaUrl) throw new Error(`Media source URL is undefined for post ${post.attachment_id}`);

            const response = await fetch(mediaUrl);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            const encryptedData = await response.json();
            if (!encryptedData.ct || !encryptedData.iv) throw new Error('Encrypted data is missing required fields');

            const decryptedArrayBuffer = await decryptBase64(encryptedData.ct, encryptedData.iv, post.attachment_key);

            await renderAvifToCanvas(decryptedArrayBuffer, dialogCanvas);

            dialogCanvas.style.width = '100%';
            dialogCanvas.style.height = 'auto';
            loadingImage.set(false);
        } catch (error) {
            console.error(`Error rendering canvas for post ${post.attachment_id}:`, error);
            displayErrorOnCanvas(dialogCanvas);
            loadingImage.set(false);
        }
    }

    function displayErrorOnCanvas(canvas: HTMLCanvasElement): void {
        const ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.fillStyle = '#f0f0f0';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = '#888888';
            ctx.font = '14px Arial';
            ctx.textAlign = 'center';
            ctx.fillText('Image load error', canvas.width / 2, canvas.height / 2);
        }
    }

    function createPostElement(post: Post, className: string, aspectRatio: number): HTMLElement {
        const cell = document.createElement('div');
        cell.className = `${className}`;

        const wrapper = document.createElement('div');
        wrapper.className = 'relative w-full h-full overflow-hidden bg-skeleton-anim-wrapper';
        wrapper.style.paddingBottom = `${aspectRatio * 100}%`;

        const canvas = document.createElement('canvas');
        canvas.id = 'canvas_' + post['attachment_id'];
        canvas.className = 'absolute top-0 left-0 w-full h-full object-cover cursor-pointer';
        canvas.style.opacity = '0';
        canvas.style.transition = 'opacity 0.3s ease-in-out';

        wrapper.appendChild(canvas);
        cell.appendChild(wrapper);
        cell.addEventListener('click', () => openModal(post));
        loadAndDisplay(post, canvas, wrapper);

        return cell;
    }

    async function openModal(post: Post): Promise<void> {
        selectedPost.set(post);
        loadingImage.set(true);
        modalOpen.set(true);
        await tick();
        await loadImageForDialog(post);
    }

    async function fetchNextPosts(): Promise<{ squarePosts: Post[]; highPosts: Post[] }> {
        const response = await fetch(`paginate?page=${$currentPage}`);
        return separatePosts(await response.json());
    }

    async function loadMorePosts(): Promise<void> {
        if ($loadingMore) return;
        loadingMore.set(true);

        const newPosts = await fetchNextPosts();
        if (newPosts.squarePosts.length === 0 && newPosts.highPosts.length === 0) {
            observer.unobserve(document.querySelector('.the-last-div') as Element);
            loadingMore.set(false);
            return;
        }

        appendToGrid(newPosts);
        currentPage.update((n) => n + 1);
        loadingMore.set(false);
    }

    function appendToGrid(posts: { squarePosts: Post[]; highPosts: Post[] }): void {
        const totalSlots = 18;
        const slotsNeededForHigh = posts.highPosts.length * 2;
        const squareSlotsAvailable = totalSlots - slotsNeededForHigh;

        posts.highPosts.forEach((post) =>
            gridElement.appendChild(
                createPostElement(
                    post,
                    'double col-span-1 row-span-2 bg-white z-10 transition-opacity duration-300 hover:opacity-85',
                    2
                )
            )
        );
        posts.squarePosts
            .slice(0, squareSlotsAvailable)
            .forEach((post) =>
                gridElement.appendChild(
                    createPostElement(
                        post,
                        'col-span-1 row-span-1 bg-white z-10 transition-opacity duration-300 hover:opacity-85',
                        1
                    )
                )
            );
    }

    function chunkArray<T>(array: T[], size: number): T[][] {
        return Array.from({ length: Math.ceil(array.length / size) }, (_, i) => array.slice(i * size, i * size + size));
    }

    let observer: IntersectionObserver;

    onMount(() => {
        appendToGrid({ squarePosts, highPosts });

        observer = new IntersectionObserver((entries) => entries[0].isIntersecting && loadMorePosts(), {
            rootMargin: '100px',
        });

        const lastDiv = document.querySelector('.the-last-div');
        if (lastDiv) observer.observe(lastDiv);

        return () => {
            if (lastDiv) observer.unobserve(lastDiv);
        };
    });
</script>

<div class="container">
    <div class="row">
        <div class="col-12 col-sm-12 col-lg-8 col-md-7 p-0">
            <div>
                <ExploreTab {authUser} />
                <div>
                    <div bind:this="{gridElement}" class="grid grid-cols-3 gap-1">
                        <!-- Grid content will be dynamically added here -->
                    </div>
                    <div class="the-last-div"></div>
                    {#if $loadingMore}
                        <div class="posts-loading-indicator flex items-center justify-center">
                            <div class="py-3">
                                <div class="spinner-border text-bmn-500" role="status">
                                    <span class="sr-only">{'Loading...'}</span>
                                </div>
                            </div>
                        </div>
                    {/if}
                </div>
            </div>
            <div>
                <Dialog.Root bind:open="{$modalOpen}">
                    <Dialog.Content class="p-0">
                        <div class="relative overflow-hidden p-0">
                            {#if $loadingImage}
                                <div class="absolute inset-0 flex items-center justify-center bg-gray-100">
                                    <div class="spinner-border text-bmn-500" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            {/if}
                            <canvas
                                bind:this="{dialogCanvas}"
                                class="max-h-[90vh] w-full overflow-hidden rounded-t-lg object-cover p-0"
                                style="aspect-ratio: {$selectedPost
                                    ? $selectedPost['aspect-ratio']
                                    : 1}; opacity: {$loadingImage ? 0 : 1}; transition: opacity 0.3s ease-in-out;"
                            ></canvas>
                        </div>
                        {#if $selectedPost}
                            <div class="mx-2 mb-3 flex justify-between">
                                <div class="flex items-center gap-x-4 px-3">
                                    <img
                                        src="{$selectedPost.author_avatar}"
                                        alt="Avatar"
                                        class="h-10 w-10 rounded-full"
                                    />
                                    <div>
                                        <div class="font-semibold">{$selectedPost.author_name}</div>
                                        <div class="text-sm text-gray-500">@{$selectedPost.author_username}</div>
                                    </div>
                                </div>
                                <div class="flex items-center">
                                    <Button href="{$selectedPost.post_url}" variant="ghost">
                                        Open post <span class="ml-2 h-4 w-4"><ArrowTopRight /></span>
                                    </Button>
                                </div>
                            </div>
                        {/if}
                    </Dialog.Content>
                </Dialog.Root>
            </div>
        </div>

        <div
            class="col-12 col-sm-12 col-md-5 col-lg-4 first border-left order-0 min-vh-100 suggestions-wrapper d-none d-md-block pb-5 pt-4"
        >
            <div class="feed-widgets">
                {#if !hide_suggestions_slider}
                    <div
                        class="suggestions-box{isMobile ? '-mobile' : ''} rounded-lg border px-2 {isMobile
                            ? 'pb-1 pt-3'
                            : 'py-4'}"
                    >
                        <div class="d-flex justify-content-between suggestions-header mb-3 px-1">
                            <h5 class="card-title mb-0 pl-2">Suggestions</h5>
                            <div class="d-flex">
                                <span class="mr-xl-3 pointer-cursor mr-2" title="Free account only">
                                    <!-- Icon component for pricetag-outline would go here -->
                                </span>
                                <span class="mr-xl-3 pointer-cursor mr-2" title="Refresh suggestions">
                                    <!-- Icon component for refresh would go here -->
                                </span>
                            </div>
                        </div>

                        <div class="suggestions-content">
                            {#if suggestions.length > 0}
                                <div class="swiper-container mySwiper">
                                    <div class="swiper-wrapper">
                                        {#each suggestions.slice(0, 5) as profile}
                                            <SuggestionCard
                                                {profile}
                                                isListMode={false}
                                                isListManageable={false}
                                            />
                                        {/each}
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                    <div class="swiper-pagination mt-4"></div>
                                </div>
                            {:else}
                                <p class="text-center">No suggestions available</p>
                            {/if}
                        </div>
                    </div>
                {/if}
            </div>
        </div>
    </div>
</div>
